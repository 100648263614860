import Vue from "vue";
import App from "./App.vue";
import Vant from "vant";
import "vant/lib/index.css";
import 'vant/lib/index.less';
import router from "./router";
import http from "./http";
import VueAwesomeSwiper from "vue-awesome-swiper";
import common from "./common/function";
import "swiper/swiper-bundle.css";
import store from "./store";
import i18n from "./lang";
import VueClipboard from 'vue-clipboard2';
import { Locale } from 'vant';
import jaJP from 'vant/es/locale/lang/ja-JP';
Locale.use('ja-JP', jaJP);


import "video.js/dist/video-js.css";

Vue.prototype.$http = http;
Vue.prototype.common = common;
Vue.config.productionTip = false;
Vue.use(VueClipboard);
Vue.use(VueAwesomeSwiper /* { default options with global component } */);
Vue.use(Vant);
new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
