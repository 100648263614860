export default {
  // 通用
  common: {
    app_name: "",
    tab_bar: {
      tab_home: "首页",
      tab_reserve: "预约",
      tab_concubine: "选妃",
      tab_video: "视频",
      tab_mine: "我的",
      tab_xy:'学院'
    },
    view_more: "查看更多",
    play: "播放",
    finished_text: "没有更多",
    description: "数据为空！",
    Details: "详情",
    disabled: "功能已禁用!",
    offline: "账号下线",
        Sure: "确定",
        Cancel:"取消",
    notset: "未设置",
    Hasbeenset: "已设置",
    logout: "退出登录",
    currency: "元",
    submit: "提交",
        save: "保存",
    success:"完成",
  },
  login: {
    login: "登录",
    forget_pwd: "忘记密码？",
    to_reg: "没有账户？马上注册",
    inp_username: "请输入用户名",
    inp_pwd: "请输入密码",
  },
  register: {
    title: "注册",
    inp_user: "请输入用户名(6-12个英文字母或数字)",
    inp_pass: "请输入登录密码(6-12个英文字母或数字)",
    inp_code: "请输入邀请码",
    agreement: '我已经知晓并同意"开户协议"各项条约',
    reg_btn: "注册",
    click_agreement: "请勾选下方开户协议！",
  },
  game: {
    title: "预约大厅",
    all: "全部",
    xzmoney: "已预定",
    create_time: "预定时间",
    update_time: "结算时间",
    Gamerules: "玩法规则",
    gametip: "玩法提示",
    gamedetial: "从可选和值形态里面选择号码进行下注",
    Winninginstructions: "中奖说明",
    Winningtip: "三个开奖号码总和值11~18为大;总和值3~ 10为小;",
    Bettingexample: "投注范例",
    Bettingexampletip: "投注方案：小;开奖号码：123,即中小",
    missionlist: "任务单",
    AvailableBalance: "可用余额",
    numberselection: "当前选号",
    Amountperbet: "每注金额",
    Pleaseentertheamount: "请输入金额",
    total: "总共",
    total1: "合计",
    note: "注",
    Clearorder: "清空订单",
    submission: "确认提交",
    Issue: "历史",
    Winningnumbers: "结果",
    Notselected: "未选择",
    Wrongamount: "金额错误！",
    selectanumber: "请选号",
    Pleasefillintheamount: "请填写金额！",
    servicetorecharge: "余额不足，请联系客服充值！",
    Unselected: "未选中",
    thistask: "请联系管理员领取该任务!",
    Thelotterydraw: "开奖成功，期号：",
      Tasktype: "任务类型",
      timeleft:"还剩购买时间",
  },
  //   首页
  home: {
    rec_tasks: "推荐任务",
    pupu_rank: "人气排行",
    rec_hot: "热门推荐",
    getting: "获取中......",
    refresh_successful: "刷新成功",
  },
  //   我的
  mine: {
    check_in: "每日签到",
    check_in_box_title: "本月你已签到{dayNum}天",
    check_in_toast: "今日已签到",
    check_in_tips_title: "奖励规则：",
    check_in_tips_1: "所有Platform Club会员均享有【LAUXANH】特别赞助的特别日期津贴，连续签到3天，俱乐部将赠送您3万日元的奖励以及免费的酒店折扣券。",
    check_in_tips_2: "仅适用于俱乐部会员",
    check_in_tips_3: "3：如果连续工作7天，您将可以免费约会1个女孩（可选）。",
    cardtitle: "填写收款卡",
    inp_cardtitle: "请输入您的收款卡信息",
    lab_bankid: "银行卡号",
    inp_bank: "银行名称",
    bindCardnote:
      "尊敬的用户,为了保障您的资金安全,请您绑定您的真实姓名和设置取款密码,如果姓名与开户名不一致,将无法取款",
    bindCard: "确认绑卡",
    inp_bankid: "请输入银行卡号",
    select_bank: "请选择银行",
    gamerecord: "任务记录",
    Recharge: "充值",
    withdraw: "提现",
    Mypurse: "我的钱包",
    balance: "余额",
    personalreport: "个人报表",
    Accountdetails: "账户明细",
    PersonalCenter: "个人中心",
    announcement: "信息公告",
    onlineservice: "在线客服",
    enter: "请完成任务单后进入",
    servicerecharge: "请联系客服充值",
    loginRegister: "登录/注册",
    logintip: "登录可享受更多服务！",
    Information: "基本信息",
    herderImg: "头像",
    selectimg: "选择头像",
    actualname: "真实姓名",
    sex: "性别",
    male: "男",
    female: "女",
    unknown: "未知",
    bindinginformation: "绑定信息",
    bound: "已绑定",
    none: "无",
    cardinformation: "收款卡信息",
    addpaymentcard: "添加收款卡",
    cardtips: "提示:请绑定大型商业银行,如需修改,请您联系在线客服",
    Setname: "请设置姓名后再绑定银行卡！",
    SetPayPassword: "请设置提现密码后再绑定银行卡！",
    gendermodification: "性别修改",
    setup: "设置",
    Basicinformation: "基本信息设置",
    loginpassword: "登录密码",
    fundpassword: "资金密码",
    withdrawalpassword: "提现密码已设置，需要修改请联系客服",
    Withdrawalcenter: "提现中心",
    Withdrawalsrecord: "提现记录",
    withdrawMoney: "提现金额 (元)",
    withdrshowPopovermin: "1.单笔限额：最低",
    withdrshowPopovermax: "最高",
    widtdrnum: "2.提现次数：一天最高提现",
    withdrsucces: "3.到账时间：一般到账时间在5分钟左右，最快2分钟内到账",
    Limitdescription: "限额说明",
    Withdrawimmediately: "马上提现",
    correctamount: "请填写正确的金额",
    Setfundpassword: "设置资金密码",
    enterthefundpassword: "请输入资金密码",
    enterthefundpasswordagain: "请再次输入资金密码",
    Pleasefillinthecomplete: "请填写完整",
    twicepassword: "两次密码不一致!",
    Changerealname: "修改真实姓名",
    Name: "姓名",
    pleaseenteryourrealname: "请输入真实姓名",
    changeralnametip: "为了您账户安全,真实姓名需要与绑定银行卡姓名一致",
    Donotrepeatsettings: "请勿重复设置！",
    Changeloginpassword: "修改登录密码",
    OldPassword: "旧密码",
    newpassword: "新密码",
    inp_OldPassword: "请输入您的旧密码",
    inp_newpassword: "请输入您的新密码",
    inp_newpassword1: "请再次输入您的新密码",
    connect: "联系",
    servicelistItemInfoText: "全天7 * 24小时竭诚为您服务",
    personalreporttitle: "盈利金额(元)",
    personalreporttip: "盈利计算公式 : 中奖金额 - 任务金额",
    personalreportplay_money: "任务金额",
    personalreportrecharge: "充值金额",
    personalreportwithdrawal: "提现金额",
      personalreportwin_money: "中奖金额",
      inp_name: "请输入您的真实姓名",
    
      rechargecenter: "充值中心",
      rechargerecord: " 充值记录",
      banktype: "银行卡",
      Depositamount: "存款金额",
      depositamount: "每次最高存款金额为",
      minimumdepositamount: "每次最低存款金额为",
      pleasecontact: "如有问题请联系",
      next: "下一步",
      bankinfo: "以下是您需要转帐的银行账户信息",
      transferinformation: "转账信息",
      copy: "复制",
      copysuccessfully: "复制成功",
      copyerr: "复制失败，请手动复制。",
      uprechargeimg: "上传转账图片",
      selectfileimg: "请先选择一张图片",
      pleaseinpmoney: "请输入正确的存款金额",
      submiterr: "提交失败",
      rechargetsuccess: "存款申请已完成，您的存款申请已成功提交",
      Amount: "金额",
      illustrate: "说明",
      Submissiontime: "提交时间",
      Reviewtime: "审核时间",
      setlang:"设置语言",
  },
  //   预约
  reserve: {},
  //   选妃
  concubine: {
    choosetitle: "选妃",
    city: "城市大全",
    cityinfo:
      "本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。",
    processprice: "流程价格",
    rigtitle1: "有哪些资源?",
    rigtitle2: "服务范围?",
    rigcontent1:
      "网红、模特、空姐、嫩模、大学生，只有您想不到，没有本平台办不到",
    rigcontent2:
      "同城免费约炮，任意地点全国空降，国内一二线城市当地均有，三线城市也可通过联系接待员预约安排。",
    rigcontent3:
          "本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。",
    Contactanadvisor:"请联系顾问或接待员",
  },
  //   视频
  video: {
    title: "视频影院",
    videoInfocount: "次播放",
    Popularrecommendations: "热门推荐",
    rechargevideo: "请充值后观看视频！",
  },
  "认证":"认证",
  "视频认证":"视频认证",
  "评分":"评分",
  "身高":"身高",
  "地域":"地域",
  "降落伞":"降落伞",
  "现在就答应她":"现在就答应她",
  "是的":"是的",
  "不是":"不是",
  "胸围":"胸围",
  "请联系管理员":"请联系管理员",
  "提交成功":"提交成功",
  "顶部":"顶部",
  "银行名":"银行名",
  "请输入银行名":"请输入银行名",
  "支店名称":"支店名称",
  "请输入支店名称":"请输入支店名称",
  "支店号码":"支店号码",
  "请输入支店号码":"请输入支店号码",
  "交易类型":"交易类型",
  "请输入交易类型":"请输入交易类型",
  "账户号码":"账户号码",
  "请输入账户号码":"请输入账户号码",
  "账户名义":"账户名义",
  "请输入账户名义":"请输入账户名义",
  "账户名义（片假名）":"账户名义（片假名）",
  "请输入账户名义（片假名）":"请输入账户名义（片假名）"
};
