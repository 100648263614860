export default {
  // 通用
  common: {
    app_name: "",
    tab_bar: {
      tab_home: "Home",
      tab_reserve: "reserve",
      tab_concubine: "Choose a concubine",
      tab_video: "video",
      tab_mine: "mine",
    },
    view_more: "see more",
    play: "play",
    finished_text: "no more",
    description: "Data is empty！",
    Details: "Details",
    disabled: "Feature disabled!",
    offline: "Account offline",
    Sure: "Sure",
    Cancel: "Cancel",
    notset: "not set",
    Hasbeenset: "Has been set",
    logout: "sign out",
    currency: "$",
    submit: "submit",
    save: "save",
    success: "success",
  },
  login: {
    login: "Login",
    forget_pwd: "forget the password？",
    to_reg: "Don’t have an account? Register now",
    inp_username: "please enter user name",
    inp_pwd: "Please enter password",
  },
  register: {
    title: "register",
    inp_user: "Please enter your username (6-12 English letters or numbers)",
    inp_pass:
      "Please enter your login password (6-12 English letters or numbers)",
    inp_code: "Please enter the invitation code",
    agreement:
      "I have understood and agreed to the terms of the Account Opening Agreement",
    reg_btn: "register",
    click_agreement: "Please check the account opening agreement below!",
  },
  game: {
    title: "Reservation hall",
    all: "all",
    xzmoney: "Booked",
    create_time: "Booking time",
    update_time: "settlement time",
    Gamerules: "Game rules",
    gametip: "Tips on how to play",
    gamedetail: "Select numbers from the optional sum format to place bets",
    Winninginstructions: "Winning instructions",
    Winningtip:
      "The total value of the three lottery numbers is 11~18, which is high; the total value is 3~10, which is small;",
    Bettingexample: "Betting example",
    Bettingexampletip:
      "Betting plan: small; lottery number: 123, that is, small and medium",
    missionlist: "mission list",
    AvailableBalance: "Available Balance",
    numberselection: "Current selection",
    Amountperbet: "Amount per bet",
    Pleaseentertheamount: "Please enter the amount",
    total: "total",
    total1: "total",
    note: "Note",
    Clearorder: "Clear order",
    submission: "confirm submission",
    Issue: "Issue",
    Winningnumbers: "Winning numbers",
    Notselected: "Not selected",
    Wrongamount: "Wrong amount！",
    selectanumber: "Please select a number",
    Pleasefillintheamount: "Please fill in the amount！",
    servicetorecharge:
      "If the balance is insufficient, please contact customer service to recharge.！",
    Unelected: "Unelected",
    thistask: "Please contact the administrator to receive this task!",
    Thelotterydraw: "The lottery draw was successful, issue number：",
    Tasktype: "Task type",
    timeleft: "Time left to buy",
  },

  //   front page
  home: {
    rec_tasks: "Recommended tasks",
    pupu_rank: "Popularity ranking",
    rec_hot: "Popular recommendations",
    getting: "Getting......",
    refresh_successful: "Refresh successful",
  },
  //   mine
  mine: {
    check_in: "Daily check-in",
    check_in_box_title: "You have signed in this month{Day Number}Day",
    check_in_toast: "Signed in today",
    check_in_tips_title: "Reward rules:",
    check_in_tips_1: "All Platform Club members enjoy special date allowances specially sponsored by [LAUXANH]. If you sign in for 3 consecutive days, the club will give you a reward of 30,000 yen and a free hotel discount coupon.",
    check_in_tips_2: "Available to club members only",
    check_in_tips_3:
      "3：If you work for 7 consecutive days, you will be able to date 1 girl for free (optional).",
    cardtitle: "Fill out the payment card",
    inp_cardtitle: "Please enter your payment card information",
    lab_bankid: "Bank card number",
    inp_bank: "Bank name",
    bindCardnote:
      "Dear user, in order to ensure the security of your funds, please bind your real name and set a withdrawal password. If your name is inconsistent with the account opening name, you will not be able to withdraw money.",
    bindCard: "Confirm card binding",
    inp_bankid: "Please enter bank card number",
    select_bank: "Please select a bank",
    gamerecord: "Task record",
    Recharge: "top up",
    withdraw: "withdraw",
    Mypurse: "My purse",
    balance: "Balance ($)",
    personalreport: "personal statement",
    Accountdetails: "Account details",
    PersonalCenter: "Personal center",
    announcement: "announcement",
    onlineservice: "online service",
    enter: "Please complete the task list before entering",
    Servicerecharge: "Please contact customer service to recharge",
    loginRegister: "log in Register",
    logintip: "Log in to enjoy more services！",
    Information: "Basic Information",
    herderImage: "avatar",
    selectimage: "Choose Avatar",
    actualname: "actual name",
    sex: "gender",
    male: "male",
    female: "female",
    unknown: "unknown",
    bindinginformation: "binding information",
    bound: "Bound",
    none: "none",
    cardinformation: "Charge card information",
    addpaymentcard: "Add payment card",
    cardtips:
      "Tip: Please bind to a large commercial bank. If you need to modify it, please contact online customer service.",
    Setname: "Please set your name before binding the bank card！",
    SetPayPassword:
      "Please set a withdrawal password before binding the bank card！",
    gendermodification: "gender modification",
    setup: "set up",
    Basicinformation: "Basic information settings",
    loginpassword: "login password",
    fundpassword: "Fund password",
    withdrawalpassword:
      "The withdrawal password has been set, please contact customer service if you need to modify it.",
    Withdrawalcenter: "Withdrawal Center",
    Withdrawalsrecord: "Withdrawals record",
    withdrawMoney: "Withdrawal amount ($)",
    withdrawshowPopovermin: "1.Single transaction limit: minimum",
    withdrawshowPopovermax: "Highest",
    withdrawnumber: "2.Number of withdrawals: Maximum withdrawal in one day",
    withdrawalssuccess:
      "3.Arrival time: The general arrival time is about 5 minutes, and the fastest arrival time is 2 minutes.",
    Limitdescription: "Limit description",
    Withdrawimmediately: "Withdraw money immediately",
    correctamount: "Please fill in the correct amount",
    Setfundpassword: "Set fund password",
    enterthefundpassword: "Please enter the fund password",
    enterthefundpasswordagain: "Please enter the fund password again",
    Pleasefillinthecomplete: "Please fill in the complete",
    twicepassword: "Two passwords are inconsistent!",
    Changerealname: "Change real name",
    Name: "Name",
    pleaseenteryourrealname: "please enter your real name",
    changerealnametip:
      "For the security of your account, your real name needs to be consistent with the name on the bound bank card.",
    Donotrepeatsettings: "Do not repeat settings！",
    Changeloginpassword: "Change login password",
    OldPassword: "Old Password",
    newpassword: "New Password",
    inp_OldPassword: "Please enter your old password",
    inp_newpassword: "Please enter your new password",
    inp_newpassword1: "Please enter your new password again",
    connect: "connect",
    servicelistItemInfoText: "Dedicated service to you 7*24 hours a day",
    personalreporttitle: "Profit amount ($)",
    personalreporttip:
      "Profit calculation formula: winning amount - task amount",
    personalreportplay_money: "Task amount",
    personalreportrecharge: "Recharge amount",
    personalreportwithdrawal: "Withdrawal Amount",
    personalreportwin_money: "Winning amount",
    inp_name: "please enter your real name",
    rechargecenter: "Recharge Center",
    rechargerecord: "Recharge record",
    banktype: "bank card",
    Depositamount: "Deposit amount",
    depositamount: "The maximum deposit amount per time is",
    minimumdepositamount: "The minimum deposit amount per time is",
    pleasecontact: "If you have any questions, please contact",
    next: "Next step",
    bankinfo:
      "The following is the bank account information you need to transfer money to",
    transferinformation: "Transfer information",
    copy: "copy",
    copysuccessfully: "Copy successfully",
    copyerr: "Copy failed, please copy manually.",
    uprechargeimg: "Upload transfer image",
    selectfileimg: "Please select a picture first",
    pleaseinpmoney: "Please enter the correct deposit amount",
    submiterr: "Submission failed",
    rechargetsuccess:
      "The deposit application has been completed and your deposit application has been submitted successfully",
    Amount: "Amount",
    illustrate: "illustration",
    Submissiontime: "Submission time",
    Reviewtime: "review time",
    setlang: "language setting",
  },
  //   reserve
  reserve: {},
  //   Choose a concubine
  concubine: {
    choosetitle: "Choose a concubine",
    city: "City Directory",
    cityinfo:
      "This platform has the most authentic peripherals + business escorts + local passion in the entire network. In order to ensure the personal privacy of each user, customers can only join through real-name recommendations by contacting the receptionist or senior members of the platform.。",
    processprice: "process price",
    rigtitle1: "What resources are there??",
    rigtitle2: "Service area?",
    rigcontent1:
      "Internet celebrities, models, flight attendants, young models, college students, you can’t think of it, but it wouldn’t be possible without this platform",
    rigcontent2:
      "Free sex in the same city, available at any location nationwide, available locally in first- and second-tier cities in China, and in third-tier cities, you can also make an appointment by contacting the receptionist。",
    rigcontent3:
      "This platform has the most authentic peripherals + business escorts + local passion in the entire network. In order to ensure the personal privacy of each user, customers can only join through real-name recommendations by contacting the receptionist or senior members of the platform.。",
    Contactanadvisor: "Contact an advisor",
  },
  //   video
  video: {
    title: "video theater",
    videoInfocount: "plays",
    Popularrecommendations: "Popular recommendations",
    rechargevideo: "Please recharge to watch the video！",
  },
  "认证": "Certification",
  "视频认证": "Video authentication",
  "评分": "score",
  "身高": "height",
  "地域": "area",
  "降落伞": "parachute",
  "现在就答应她": "Promise her now",
  "是的": "Yes",
  "不是": "no",
  "胸围": "chest circumference",
  "请联系管理员": "Please contact the administrator",
  "提交成功": "Submission successful",
  "顶部": "top",
  "支店名称": "Branch name",
  "请输入支店名称": "Please enter branch name",
  "支店号码": "Branch number",
  "请输入支店号码": "Please enter the branch number",
  "交易类型": "transaction type",
  "请输入交易类型": "Please enter transaction type",
  "账户号码": "Account number",
  "请输入账户号码": "Please enter account number",
  "账户名义": "Account name",
  "请输入账户名义": "Please enter the account name",
  "账户名义（片假名）": "Account name (katakana)",
  "请输入账户名义（片假名）": "Please enter the account name (katakana)",
  "银行名": "Bank name",
  "请输入银行名": "Please enter bank name"

};
