export default {
    // 通用
    common: {
      app_name: "",
      tab_bar: {
        tab_home: "Frente",
        tab_reserve: "Reserva",
        tab_concubine: "Escolher",
        tab_video: "Vídeo",
        tab_mine: "Meu",
      },
      view_more: "ver mais",
      play: "jogar",
      finished_text: "não mais",
      description: "Os dados estão vazios！",
      Details: "Detalhes",
      disabled: "Recurso desativado!",
      offline: "Conta off-line",
          Sure: "Claro",
          Cancel:"Cancelar",
      notset: "não configurado",
      Hasbeenset: "Foi definido",
      logout: "sair",
      currency: "Real",
      submit: "enviar",
          save: "salvar",
      success:"Terminar",
    },
    login: {
      login: "Conecte-se",
      forget_pwd: "esqueça a senha？",
      to_reg: "Não tem uma conta? Registrar agora",
      inp_username: "por favor insira o nome de usuário",
      inp_pwd: "Por favor insira a senha",
    },
    register: {
      title: "registro",
      inp_user: "Por favor, digite seu nome de usuário (6 a 12 letras ou números em inglês)",
      inp_pass: "Por favor, digite sua senha de login (6 a 12 letras ou números em inglês)",
      inp_code: "Por favor insira o código do convite",
      agreement: 'Compreendi e concordei com os termos do "Acordo de Abertura de Conta"',
      reg_btn: "registro",
      click_agreement: "Por favor, verifique o acordo de abertura de conta abaixo！",
    },
    game: {
      title: "Sala de reservas",
      all: "todos",
      xzmoney: "Já reservado",
      create_time: "hora marcada",
      update_time: "hora de liquidação",
      Gamerules: "Regras do jogo",
      gametip: "Dicas sobre como jogar",
      gamedetial: "Selecione números no formato de soma opcional para fazer apostas",
      Winninginstructions: "Instruções vencedoras",
      Winningtip: "A soma dos três números da loteria é de 11 a 18, o que é alto; a soma de 3 a 10 é pequena.;",
      Bettingexample: "Exemplo de apostas",
      Bettingexampletip: "Plano de apostas: pequeno; número da loteria: 123, ou seja, pequeno e médio",
      missionlist: "lista de missão",
      AvailableBalance: "Saldo disponível",
      numberselection: "Seleção atual",
      Amountperbet: "Valor por aposta",
      Pleaseentertheamount: "Por favor insira o valor",
      total: "total",
      total1: "total",
      note: "bilhete",
      Clearorder: "Limpar pedido",
      submission: "confirmar envio",
      Issue: "história",
      Winningnumbers: "resultado",
      Notselected: "Não selecionado",
      Wrongamount: "Quantidade errada！",
      selectanumber: "Selecione número",
      Pleasefillintheamount: "Por favor preencha o valor！",
      servicetorecharge: "Se o saldo for insuficiente, entre em contato com o atendimento ao cliente para recarregar.！",
      Unselected: "Não selecionado",
      thistask: "Entre em contato com o administrador para receber esta tarefa!",
      Thelotterydraw: "O sorteio da loteria foi bem-sucedido, número da edição：",
        Tasktype: "Tipo de tarefa",
        timeleft:"Tempo restante para comprar",
    },
    //   首页
    home: {
      rec_tasks: "Tarefas recomendadas",
      pupu_rank: "Classificação de popularidade",
      rec_hot: "Recomendações populares",
      getting: "Recebendo......",
      refresh_successful: "Atualização bem-sucedida",
    },
    //   我的
    mine: {
      check_in: "Check-in diário",
      check_in_box_title: "Você fez check-in há {dayNum} dias este mês",
      check_in_toast: "Conectado hoje",
      check_in_tips_title: "Regras de recompensa:",
      check_in_tips_1: "Todos os membros do Platform Club desfrutam de datas especiais patrocinadas especialmente por [LAUXANH]. Se você se inscrever por 3 dias consecutivos, o clube lhe dará uma recompensa de 30.000 ienes e um cupom de desconto de hotel grátis.",
      check_in_tips_2: "Disponível apenas para associados do clube",
      check_in_tips_3: "3：Se você trabalhar 7 dias consecutivos, poderá namorar 1 garota de graça (opcional)。",
      cardtitle: "Preencha o cartão de pagamento",
      inp_cardtitle: "Por favor insira as informações do seu cartão de pagamento",
      lab_bankid: "Número do cartão bancário",
      inp_bank: "nome do banco",
      bindCardnote:
        "Prezado usuário, para garantir a segurança de seus fundos, vincule seu nome verdadeiro e defina uma senha de saque. Se o seu nome for inconsistente com o nome de abertura da conta, você não poderá sacar dinheiro.",
      bindCard: "Confirmar vinculação do cartão",
      inp_bankid: "Insira o número do cartão bancário",
      select_bank: "Selecione um banco",
      gamerecord: "Registro de tarefa",
      Recharge: "completar",
      withdraw: "retirar",
      Mypurse: "Minha bolsa",
      balance: "equilíbrio",
      personalreport: "relatório pessoal",
      Accountdetails: "Detalhes da conta",
      PersonalCenter: "Centro Pessoal",
      announcement: "anúncio",
      onlineservice: "serviço on-line",
      enter: "Por favor, complete a lista de tarefas antes de entrar",
      servicerecharge: "Entre em contato com o atendimento ao cliente para recarregar",
      loginRegister: "faça login Cadastre-se",
      logintip: "Faça login para desfrutar de mais serviços！",
      Information: "Informação básica",
      herderImg: "avatar",
      selectimg: "Escolha Avatar",
      actualname: "actual name",
      sex: "gênero",
      male: "macho",
      female: "fêmea",
      unknown: "desconhecido",
      bindinginformation: "informação vinculativa",
      bound: "vinculativo",
      none: "nenhum",
      cardinformation: "Informações do cartão de cobrança",
      addpaymentcard: "Adicionar cartão de pagamento",
      cardtips: "Dica: Por favor, vincule-se a um grande banco comercial. Se precisar modificá-lo, entre em contato com o atendimento ao cliente on-line.",
      Setname: "Por favor, defina seu nome antes de vincular o cartão bancário！",
      SetPayPassword: "Defina uma senha de saque antes de vincular o cartão bancário！",
      gendermodification: "modificação de gênero",
      setup: "configurar",
      Basicinformation: "Configurações básicas de informações",
      loginpassword: "senha de login",
      fundpassword: "Senha do fundo",
      withdrawalpassword: "A senha de retirada foi definida, entre em contato com o atendimento ao cliente se precisar modificá-la.",
      Withdrawalcenter: "Centro de retirada",
      Withdrawalsrecord: "Registro de retiradas",
      withdrawMoney: "Valor da retirada (Real)",
      withdrshowPopovermin: "1.Limite de transação única: mínimo",
      withdrshowPopovermax: "Altíssima",
      widtdrnum: "2.Número de saques: Saque máximo em um dia",
      withdrsucces: "3.Hora de chegada: A hora geral de chegada é de cerca de 5 minutos, e a hora de chegada mais rápida é de 2 minutos.",
      Limitdescription: "Descrição do limite",
      Withdrawimmediately: "Retire dinheiro imediatamente",
      correctamount: "Por favor preencha o valor correto",
      Setfundpassword: "Definir senha do fundo",
      enterthefundpassword: "Por favor insira a senha do fundo",
      enterthefundpasswordagain: "Por favor, insira a senha do fundo novamente",
      Pleasefillinthecomplete: "Por favor preencha o completo",
      twicepassword: "Duas senhas são inconsistentes!",
      Changerealname: "Alterar nome verdadeiro",
      Name: "Nome",
      pleaseenteryourrealname: "por favor digite seu nome verdadeiro",
      changeralnametip: "Para a segurança da sua conta, o seu nome verdadeiro deve ser consistente com o nome do cartão bancário vinculado a ela.",
      Donotrepeatsettings: "Não repita as configurações！",
      Changeloginpassword: "Alterar senha de login",
      OldPassword: "Senha Antiga",
      newpassword: "Nova Senha",
      inp_OldPassword: "Por favor digite sua senha antiga",
      inp_newpassword: "Por favor digite sua nova senha",
      inp_newpassword1: "Por favor digite sua nova senha novamente",
      connect: "conectar",
      servicelistItemInfoText: "Serviço dedicado para você 7*24 horas por dia",
      personalreporttitle: "Valor do lucro (Real)",
      personalreporttip: "Fórmula de cálculo de lucro: valor do ganho - valor da tarefa",
      personalreportplay_money: "Quantidade da tarefa",
      personalreportrecharge: "Valor de recarga",
      personalreportwithdrawal: "Quantidade retirada",
        personalreportwin_money: "Valor ganho",
        inp_name: "por favor digite seu nome verdadeiro",
      
        rechargecenter: "centro de recarga",
        rechargerecord: " Registro de recarga",
        banktype: "cartão do banco",
        Depositamount: "Valor do depósito",
        depositamount: "O valor máximo do depósito por vez é",
        minimumdepositamount: "O valor mínimo do depósito por vez é",
        pleasecontact: "Se você tiver alguma dúvida entre em contato",
        next: "Próxima Etapa",
        bankinfo: "Abaixo estão as informações da conta bancária para a qual você precisa transferir dinheiro",
        transferinformation: "Informações de transferência",
        copy: "cópia",
        copysuccessfully: "Copiado com sucesso",
        copyerr: "Falha na cópia, copie manualmente。",
        uprechargeimg: "Carregar imagem de transferência",
        selectfileimg: "Selecione uma imagem primeiro",
        pleaseinpmoney: "Por favor insira o valor correto do depósito",
        submiterr: "Falha no envio",
        rechargetsuccess: "O pedido de depósito foi concluído, seu pedido de depósito foi enviado com sucesso",
        Amount: "Quantia",
        illustrate: "ilustrar",
        Submissiontime: "Hora de envio",
        Reviewtime: "tempo de revisão",
        setlang:"configuração de idioma",
    },
    //   预约
    reserve: {},
    //   选妃
    concubine: {
      choosetitle: "Escolha uma concubina",
      city: "Diretório de cidades",
      cityinfo:
        "Esta plataforma é o mais autêntico periférico + acompanhante de negócios + paixão local de toda a rede. De forma a garantir a privacidade pessoal de cada utilizador, os clientes só podem aderir através de recomendações de nomes reais, contactando a recepcionista ou membros seniores da plataforma.。",
      processprice: "preço do processo",
      rigtitle1: "Que recursos existem?",
      rigtitle2: "área de serviço?",
      rigcontent1:
        "Celebridades da Internet, modelos, comissários de bordo, jovens modelos, estudantes universitários, você não consegue pensar nisso, mas não seria possível sem esta plataforma",
      rigcontent2:
        "Sexo grátis na mesma cidade, disponível em qualquer local do país, disponível localmente em cidades de primeiro e segundo nível na China e em cidades de terceiro nível, você também pode marcar uma consulta entrando em contato com a recepcionista。",
      rigcontent3:
            "Esta plataforma é o mais autêntico periférico + acompanhante de negócios + paixão local de toda a rede.Para garantir a privacidade pessoal de cada utilizador, os clientes só podem aderir através de recomendações de nomes reais, contactando a recepcionista ou membros seniores da plataforma.。",
      Contactanadvisor:"Entre em contato com um consultor ou recepcionista",
    },
    //   视频
    video: {
      title: "videoteatro",
      videoInfocount: "tocam",
      Popularrecommendations: "Recomendações populares",
      rechargevideo: "Por favor, recarregue e assista ao vídeo！",
    },
    "认证": "Certificação",
    "视频认证": "Autenticação de vídeo",
    "评分": "pontuação",
    "身高": "altura",
    "地域": "área",
    "降落伞": "pára-quedas",
    "现在就答应她": "Prometa a ela agora",
    "是的": "Sim",
    "不是": "não",
    "胸围": "circunferência torácica",
    "请联系管理员": "Entre em contato com o administrador",
    "提交成功": "Envio bem-sucedido",
    "顶部": "principal",
    "支店名称": "Nome da filial",
    "请输入支店名称": "Por favor insira o nome da filial",
    "支店号码": "Número da filial",
    "请输入支店号码": "Por favor insira o número da agência",
    "交易类型": "tipo de transação",
    "请输入交易类型": "Por favor insira o tipo de transação",
    "账户号码": "Número de conta",
    "请输入账户号码": "Por favor insira o número da conta",
    "账户名义": "Nome da conta",
    "请输入账户名义": "Por favor insira o nome da conta",
    "账户名义（片假名）": "Nome da conta (katakana)",
    "请输入账户名义（片假名）": "Por favor insira o nome da conta (katakana)",
      "银行名": "Nome do banco",
  "请输入银行名": "Por favor insira o nome do banco"
  };
  